import { createContext } from "react";
import jwt_decode from "jwt-decode";
// import { NotificationManager } from "react-notifications";
import axios from "axios";
import axiosAuth from "../axios/Interceptors";
const AppContext = createContext(null) as any;

AppContext.LogInUserName = "";

AppContext.AppBaseURL = "https://commapi.manyge.com/";
AppContext.FilesAPI = "https://filesapi.manyge.com/";
AppContext.GetCommFiles = "https://files.manyge.com/commfiles/";
AppContext.Domain = "domain=.manyge.com";

//AppContext.AppBaseURL = "https://localhost:7112/";
//AppContext.Domain = "domain=localhost";

AppContext.SignalRConnectionID = "";

AppContext.LiveLinkPostFix = "stg";

AppContext.SelectedObject = { wid: "", cid: "" };

AppContext.SetSelectedObject = () => {
  let SelectedWorkSpace = AppContext.GetLocalStorageObject("SelectedWorkSpace");
  if (
    SelectedWorkSpace &&
    SelectedWorkSpace != null &&
    SelectedWorkSpace !== ""
  ) {
    AppContext.SelectedObject.wid = SelectedWorkSpace.Code;
  }
};

AppContext.SetCookie = (cname: any, cvalue: any, exmins: any) => {
  const d = new Date();
  if (exmins == 0) {
    d.setTime(0);
  } else {
    d.setTime(d.getTime() + exmins * 60 * 1000);
  }
  let expires = "expires=" + d;
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";" + AppContext.Domain + ";path=/";
};

AppContext.GetCookie = (cname: any) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

AppContext.SetLocalStorage = (Name: any, Value: any) => {
  localStorage.setItem(Name, Value);
};

AppContext.GetLocalStorage = (Name: any) => {
  let LocalData = localStorage.getItem(Name);
  return LocalData;
};

AppContext.SetLocalStorageObject = (Name: any, Value: any) => {
  localStorage.setItem(Name, JSON.stringify(Value));
  AppContext.SetSelectedObject();
};

AppContext.GetLocalStorageObject = (Name: any) => {
  let NewObject: any = localStorage.getItem(Name);
  // AppContext.SetSelectedObject();
  return JSON.parse(NewObject);
};

AppContext.ConvertDateTimeToLocal = (date: any) => {
  date = new Date(date);
  var localOffset = date.getTimezoneOffset() * 60000;
  var localTime = date.getTime();
  date = localTime - localOffset;
  date = new Date(date);

  // let text = date.toLocaleString();
  // date = new Date(text);

  return date;
};

AppContext.DateDifference = (MessageDate: any) => {
  MessageDate = AppContext.ConvertDateTimeToLocal(MessageDate);
  let date_now: any = new Date();

  let seconds = Math.floor((date_now - MessageDate) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  let l_strTime = "";

  if (days > 0) {
    if (days > 1) {
      l_strTime = days + " days ago.";
    } else {
      l_strTime = days + " day ago.";
    }
  } else if (hours > 0) {
    if (hours > 1) {
      l_strTime = hours + " hours ago.";
    } else {
      l_strTime = hours + " hour ago.";
    }
  } else if (minutes > 0) {
    if (minutes > 1) {
      l_strTime = minutes + " minutes ago.";
    } else {
      l_strTime = minutes + " minute ago.";
    }
  } else if (seconds > 0) {
    // if (seconds > 1) {
    //   l_strTime = seconds + " seconds ago.";
    // } else {
    //   l_strTime = seconds + " second ago.";
    // }

    l_strTime = "less than minute ago.";
  }

  return l_strTime;
};

AppContext.ShowNotification = (Type: any, Message: any) => {
  switch (Type) {
    case "info":
      // NotificationManager.info(Message);
      alert(Message);
      break;
    case "success":
      //NotificationManager.success(Message, "Success");
      alert(Message);
      break;
    case "warning":
      //NotificationManager.warning(Message, "Close after 3000ms", 3000);
      alert(Message);
      break;
    case "error":
      // NotificationManager.error(Message, "Error!", 5000, () => {
      //alert('callback');
      alert(Message);
      //});
      break;
  }
};

AppContext.GetJWTAndDecode = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo: any = jwt_decode(token);
  if (UserInfo && UserInfo !== "") {
    AppContext.LogInUserName = UserInfo.username;
    //Router.push("welcome");
  } else {
    //Router.push("login");
  }
};

AppContext.IsUserLoggedIn = async (p_WantRedirections = false) => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "" && !AppContext.IsJwtExpired(token)) {
    AppContext.SetUserName();
    return true;
  } else {
    await AppContext.GetNewJWTTokenFromRefreshToken(p_WantRedirections);
    token = AppContext.GetCookie("Token");
    if (token && token !== "" && !AppContext.IsJwtExpired(token)) {
      AppContext.SetUserName();
      return true;
    } else {
      return false;
    }
  }
};

AppContext.IsJwtExpired = (token: any) => {
  if (token && token !== "") {
    var UserInfo: any = jwt_decode(token);
    let currentDate = new Date();
    if (UserInfo.exp * 1000 < currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

AppContext.GetInitials = (fullName: any) => {
  let initials = "PT";
  if (fullName !== "") {
    const allNames = fullName.trim().split(" ");
    initials = allNames.reduce((acc: any, curr: any, index: any) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
  }
  return initials;
};

AppContext.SetUserName = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo: any = jwt_decode(token);
  if (UserInfo && UserInfo !== "") {
    AppContext.LogInUserName = UserInfo.username;
  }
};

AppContext.GetNewJWTTokenFromRefreshToken = async (p_WantRedirections: any) => {
  var RefreshToken = AppContext.GetCookie("RefreshToken");
  if (RefreshToken && RefreshToken !== "") {
    RefreshToken = RefreshToken.replaceAll("tkn_st", "");
  }
  if (
    RefreshToken &&
    RefreshToken !== "" &&
    !AppContext.IsJwtExpired(RefreshToken)
  ) {
    let object: any = {};
    object.token = RefreshToken;

    await axios
      .post(
        "https://authapi.manyge.com/api/SiteUser/RefreshToken",
        await object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          AppContext.SetCookie("Token", response.data.Data[0].Token, 30);
          AppContext.SetUserName();
          // var l_url = Router.router.state.route
          // Router.push(l_url);
        } else {
          AppContext.SetCookie("Token", "", 0);
          AppContext.SetCookie("RefreshToken", "", 0);
          if (p_WantRedirections)
            window.location = ("https://auth" +
              AppContext.LiveLinkPostFix +
              ".manyge.com/login") as any;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        AppContext.SetCookie("Token", "", 0);
        AppContext.SetCookie("RefreshToken", "", 0);
        if (p_WantRedirections)
          window.location = ("https://auth" +
            AppContext.LiveLinkPostFix +
            ".manyge.com/login") as any;
      });
  } else {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    if (p_WantRedirections)
      window.location = ("https://auth" +
        AppContext.LiveLinkPostFix +
        ".manyge.com/login") as any;
  }
};

AppContext.SetMyWorkSpaces = (p_MGWorkspaceID: any) => {
  let object: any = {};
  object.mgworkspaceid = p_MGWorkspaceID;
  axiosAuth
    .post(
      "https://authapi.manyge.com/api/MGWorkspace/SetMyWorkSpacesSelection",
      object
    )
    .then((response) => {})
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

AppContext.SetMySelectedChannel = (p_MGChennelid: any) => {
  let object: any = {};
  //object.mgmessagechannelid = p_MGChennelid;
  object.channelid = p_MGChennelid;
  axiosAuth
    .post(
      "https://commapi.manyge.com/api/MGMessage/SetMySelectedChannel",
      object
    )
    .then((response) => {})
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

AppContext.PTCheckPasswordValidation = (FieldValue: any) => {
  //var RegExpresson = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  var RegExpresson = /^(?=.*["}{)(|,'?><_~!@#\$%\^&\*^%:;.`/-])/;
  //var l_boolValid = RegExpresson.test(FieldValue);
  var l_boolValid = true;
  var HasWhiteSpace = false;
  var Message = "";
  if (/\s/.test(FieldValue)) {
    // It has any kind of whitespace
    HasWhiteSpace = true;
  }
  if (HasWhiteSpace) {
    l_boolValid = false;
    IsAnyError = true;

    Message =
      "<br /><span class='p-error'>White spaces are not allowed.</span>";
  }
  var IsAnyError = false;
  if (FieldValue.length < 8) {
    Message +=
      "<br /><span class='p-error'>A minimum of 8 characters required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>A minimum of 8 characters required.</span>";
  }

  if (!/[a-z]/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one lowercase letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one lowercase letter is required.</span>";
  }

  if (FieldValue == FieldValue.toLowerCase()) {
    Message +=
      "<br /><span class='p-error'>At least one capital letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one capital letter is required.</span>";
  }

  if (!/\d/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one number is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one number is required.</span>";
  }
  // if (/^[a-zA-Z0-9- ]*$/.test(FieldValue) == true) {
  if (!RegExpresson.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one special character (e.g. @) is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<span class='p-ok'>At least one special character (e.g. @) is required.</span>";
  }
  // }

  return { Valid: l_boolValid, Message: Message };
};

AppContext.GenerateUniqueGUID = () => {
  return (
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }) +
    "-" +
    Date.now()
  );
};

AppContext.GetFileIconToDisplay = (FilePath: any) => {
  var IconURL = FilePath;
  var parts = FilePath.split(".");
  var ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    // case "jpeg":
    //   IconURL = FilePath;
    //   break;
    // case "jpg":
    //   IconURL = FilePath;
    //   break;
    // case "png":
    //   IconURL = FilePath;
    //   break;
    // case "tif":
    //   IconURL = FilePath;
    //   break;
    // case "tiff":
    //   IconURL = FilePath;
    //   break;
    case "pdf":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/pdficon.jpg";
      break;
    case "docx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/Word.jpg";
      break;
    case "doc":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/Word.jpg";
      break;
    case "xlsx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/xls_icon.png";
      break;
    case "xls":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/xls_icon.png";
      break;
    case "pptx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/ppt.png";
      break;
    case "ppt":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/ppt.png";
      break;
    case "wav":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "mid":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "midi":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "wma":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "mp3":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "ogg":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "rma":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "au":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "ea":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "avi":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "mp4":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "divx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "wmv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "mkv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "flv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "avi":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "vob":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "ogv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "wmv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "m4v":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "mov":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "wmv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "zip":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/zip.png";
      break;
    case "rar":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/zip.png";
      break;
    default:
      IconURL = FilePath;
  }
  return IconURL;
};

AppContext.DownSecureFile = (FileName: any, FilePath: any) => {
  var RefreshToken = AppContext.GetCookie("RefreshToken");
  debugger;
  axiosAuth
    .get(FilePath, {
      headers: {
        custckies: RefreshToken,
      },
    })
    .then((response) => {
      AppContext.DownloadDataFileBase64(FileName, response.data);
      debugger;
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

AppContext.DownloadDataFile = (filename: any, dataUrl: any) => {
  // Construct the 'a' element
  var link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";

  // Construct the URI
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
};

AppContext.DownloadDataFileBase64 = (filename: any, data: any) => {
  // Construct the 'a' element
  var link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";

  // Construct the URI
  link.href = `data:image/jpg;base64,${data}`;
  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
};

export default AppContext;
