export function WriteAndDownloadFile(filename: string, content: string) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export async function getFileFromUrl(
  url: any,
  name: any,
  defaultType = "image/jpeg"
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function ConvertSecondsIntoDayTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "0d:0h:0m:0s";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((TimeDistance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    DisplayTime = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
  }
  return DisplayTime;
}

export function ConvertSecondsIntoTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "00:00:00";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60)) / (1000 * 60)
    );
    var seconds: any = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    DisplayTime = hours + ":" + minutes + ":" + seconds;
  }
  return DisplayTime;
}

export function GetSelectedIDsFromListWithComma(p_strID: any) {
  var l_intSelectedIDs = "";
  var l_strList = document.getElementsByName(p_strID) as any;

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
      if (l_intSelectedIDs == "") l_intSelectedIDs = l_strList[i].value;
      else l_intSelectedIDs = l_intSelectedIDs + "," + l_strList[i].value;
    }
  }
  return l_intSelectedIDs;
}

export function SortTheList(List: any, SortBy: any, Direction: any) {
  if (Direction == "A") return List.sort(GetSortOrderAsc(SortBy));
  else return List.sort(GetSortOrderDesc(SortBy));
}

function GetSortOrderAsc(prop: any) {
  return function (a: any, b: any) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortOrderDesc(prop: any) {
  return function (a: any, b: any) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}