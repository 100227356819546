import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import moment from "moment";
import { GetSelectedIDsFromListWithComma } from "../../Shared/Utility";
import StatusView from "./StatusView";
import ProjectView from "./ProjectView";
import EventDetailsView from "./EventDetailsView";
import TasksComm from "./TasksComm";
import ResourceView from "./ResourceView";
function TasksKanbanView() {
  const [filterData, setFilterData] = useState({
    MGWorkspaceID: 1,
    MGProjectIDs: "",
    StartDateTime: null,
    EndDateTime: null,
    ShowProjectsList: false,
    IsFirstTime: true,
    CurrentView: "ResourceView",
  });

  const [ProjectsList, SetProjectsList] = useState<any>([]);

  const [TasksList, SetTasksList] = useState<any>([]);
  const [ResourceList, SetResourceList] = useState<any>([]);
  const [StatusList, SetStatusList] = useState<any>([]);
  const [CurrentProjectsList, SetCurrentProjectsList] = useState<any>([]);

  const [CurrentlySelectedEvent, SetCurrentlySelectedEvent] = useState<any>({});

  const [MGEventData, setMGEventData] = useState<any>({});
  const [showAddMGEventModal, setShowAddMGEventModal] = useState(false);

  useEffect(() => {
    MGEventGetDataSetForTasksKanbanView(1);
  }, []);

  useEffect(() => {
    if (ProjectsList.length > 0) applyDefaultFilters();
  }, [ProjectsList]);

  function MGEventGetDataSetForTasksKanbanView(type: number) {
    let object = {} as any;
    object.mgworkspaceid = filterData.MGWorkspaceID;
    object.mgprojectids = filterData.MGProjectIDs;
    object.startdatetime = filterData.StartDateTime;
    object.enddatetime = filterData.EndDateTime;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/MGEvent/MGEventGetDataSetForTasksKanbanView",
        object
      )
      .then((response: any) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          if (result != null && result.length > 0) {
            SetProjectsList(result[0]);
            SetTasksList(result[1]);
            SetResourceList(result[2]);
            SetStatusList(result[3]);
            SetCurrentProjectsList(result[4]);

            if (type == 3) {
              var filtered = result[1].filter(
                (item: any) =>
                  item.MGEventID == CurrentlySelectedEvent.MGEventID
              );
              if (filtered.length > 0) SetCurrentlySelectedEvent(filtered[0]);
            }
          }
        }
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
      });
  }

  function applyDefaultFilters() {
    if (filterData.IsFirstTime) {
      let MGProjectIDs = getAllProjectIDsCommaSeperated();
      let today = new Date();
      let oneMonthBefore = new Date(today.setMonth(today.getMonth() - 1));
      let EndDateTime = new Date();

      setFilterData((prevState: any) => ({
        ...prevState,
        MGProjectIDs: MGProjectIDs,
        StartDateTime: oneMonthBefore,
        EndDateTime: EndDateTime,
        IsFirstTime: false,
      }));
    }
  }

  function getAllProjectIDsCommaSeperated() {
    let MGProjectIDs = "-1";
    for (let i = 0; i < ProjectsList.length; i++) {
      if (MGProjectIDs == "") MGProjectIDs = ProjectsList[i].MGProjectID;
      else MGProjectIDs += "," + ProjectsList[i].MGProjectID;
    }
    return MGProjectIDs;
  }

  function isThisChecked(formattingOptions: any, className: any) {
    if (formattingOptions != undefined && formattingOptions != "") {
      let ids = formattingOptions.split(",");
      let bool = false;
      for (let index = 0; index < ids.length; index++) {
        if (ids[index] == className) {
          bool = true;
          break;
        }
      }
      return bool;
    }
    return false;
  }

  function selectOnlyOne(group: number, id: any, index: any) {
    if (group == 1) {
      for (let i = 0; i < ProjectsList.length; i++) {
        let element = document.getElementById(id + i) as any;
        if (index != i) {
          //element.checked = false;
        } else {
          // element.checked = !element.checked;
        }
      }
    }

    let allProjectsList = getAllProjectIDsCommaSeperated();
    allProjectsList = allProjectsList.replace("-1,", "");
    let selectedProjectsList = GetSelectedIDsFromListWithComma("ProjectsList");
    if (allProjectsList != selectedProjectsList)
      selectedProjectsList = selectedProjectsList.replace("-1,", "");
    else selectedProjectsList = "-1," + allProjectsList;

    setFilterData((prevState: any) => ({
      ...prevState,
      MGProjectIDs: selectedProjectsList,
    }));
  }

  function handleChildClick(type: number, data: any) {
    if (type == 1) SetCurrentlySelectedEvent(data); //MM:: Set Event Details
    if (type == 2) SetCurrentlySelectedEvent(data); //MM:: Remove Event Details
    if (type == 3) MGEventGetDataSetForTasksKanbanView(3); //MM:: After creating Task Internal Channel refresh data
    if (type == 4 || type == 5 || type == 6) {
      //MM:: Add new event, when clicked on add icon
      let MGEventDataLocal = {} as any;
      if (type == 4) {
        MGEventDataLocal.SiteUserID = data.SiteUserID;
        MGEventDataLocal.MGEventStatusID = 0;
        MGEventDataLocal.MGProjectID = 0;
      } else if (type == 5) {
        MGEventDataLocal.SiteUserID = 0;
        MGEventDataLocal.MGEventStatusID = data.MGEventStatusID;
        MGEventDataLocal.MGProjectID = 0;
      } else if (type == 6) {
        MGEventDataLocal.SiteUserID = 0;
        MGEventDataLocal.MGEventStatusID = 0;
        MGEventDataLocal.MGProjectID = data.MGProjectID;
      }
      setMGEventData(MGEventDataLocal);
      setShowAddMGEventModal(true);
    }
  }

  function MGEventSaveCustom() {
    let data = {} as any;
    data.mgeventvisibilityid = 1;
    data.mgeventrepeatmodeid = 1;
    data.mgeventstatusid = MGEventData.MGEventStatusID;
    data.mgprojectid = MGEventData.MGProjectID;
    data.siteuserid = MGEventData.SiteUserID;
    data.mgeventtypeid = 1;
    data.name = MGEventData.Name;
    data.description = MGEventData.Description;
    data.startdatetime = new Date();
    data.objectstatus = 1;

    //MM:: Channel related data
    data.mgmessagecategoryid = 2;
    data.title = MGEventData.Name;
    data.permissiontype = 1;

    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGEvent/MGEventSaveCustom", data)
      .then((response) => {
        if (response.data.Status === 1) {
          MGEventGetDataSetForTasksKanbanView(4);
          setMGEventData({});
          setShowAddMGEventModal(false);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <div className="msg-main">
        <div className="msg--menu">
          <div className="msg--menu__head">
            <div className="msg--menu__head__content">
              <span className="msg--menu__username"></span>
            </div>
          </div>
          <div className="msg--menu__ctg">
            <div className="msg--menu__ctg__main"></div>
          </div>
          <div className="msg--menu__ctg">
            <div className="msg--menu__ctg__main"></div>
            <div className="msg--menu__ctg__top">
              <div
                className="ctg--top__lt"
                onClick={(e) => {
                  let ShowProjectsList = !filterData.ShowProjectsList;
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    ShowProjectsList: ShowProjectsList,
                  }));
                }}
              >
                <div className="top__lt__fst">
                  {filterData.ShowProjectsList ? (
                    <div className="top__lt__fst">
                      <img
                        src="/images/downg.png"
                        alt="img"
                        width="10px"
                        height="5px"
                      />
                    </div>
                  ) : (
                    <div className="top__lt__fst">
                      <img
                        src="/images/rightg.png"
                        alt="img"
                        width="5px"
                        height="10px"
                      />
                    </div>
                  )}
                </div>
                <span>Projects</span>
              </div>
            </div>
            <>
              {filterData.ShowProjectsList &&
                ProjectsList != null &&
                ProjectsList.length > 0 && (
                  <div className="msg--menu__chat custom--scroll body-full-scrollh auto-height p--b10 grid-d">
                    <div className="grid-d flex-gap5 p--t15">
                      <div
                        className="flex-d flex-ac flex-gap10"
                        key={"project" + 0}
                      >
                        <React.Fragment>
                          <div className="input--radio check--btn sm-check--btn">
                            <input
                              className="input--type__check"
                              type="checkbox"
                              name="ProjectsList"
                              value={-1}
                              checked={isThisChecked(
                                filterData.MGProjectIDs,
                                -1
                              )}
                              id={"ck1" + -1}
                              onChange={(e) => {
                                let allProjectsList =
                                  getAllProjectIDsCommaSeperated();
                                if (!e.target.checked) allProjectsList = "";
                                setFilterData((prevState: any) => ({
                                  ...prevState,
                                  MGProjectIDs: allProjectsList,
                                }));
                              }}
                            />
                            <span className="check--mark"></span>
                            <span className="input--check__text">
                              Select/Unselect All
                            </span>
                          </div>
                        </React.Fragment>
                      </div>
                      {ProjectsList &&
                        ProjectsList.map((item: any, index: any) => (
                          <div
                            className="flex-d flex-ac flex-gap10"
                            key={"project" + index}
                          >
                            <React.Fragment>
                              <div className="input--radio check--btn sm-check--btn">
                                <input
                                  className="input--type__check"
                                  type="checkbox"
                                  name="ProjectsList"
                                  value={item.MGProjectID}
                                  checked={isThisChecked(
                                    filterData.MGProjectIDs,
                                    item.MGProjectID
                                  )}
                                  id={"ck1" + index}
                                  onChange={() =>
                                    selectOnlyOne(1, "ck1", index)
                                  }
                                />
                                <span className="check--mark"></span>
                                <span className="input--check__text">
                                  {item.Name}
                                </span>
                              </div>
                            </React.Fragment>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
            </>
          </div>
          <div className="grid-d flex-gap10 p-lr15 mt--20">
            <input
              type="date"
              className="input--type__text"
              value={moment(filterData.StartDateTime).format("YYYY-MM-DD")}
              onChange={(e) => {
                setFilterData((prevState: any) => ({
                  ...prevState,
                  StartDateTime: e.target.value,
                }));
              }}
            />
            <input
              type="date"
              className="input--type__text"
              value={moment(filterData.EndDateTime).format("YYYY-MM-DD")}
              onChange={(e) => {
                setFilterData((prevState: any) => ({
                  ...prevState,
                  EndDateTime: e.target.value,
                }));
              }}
            />
            <div className="modal--button">
              <a
                className="w-100"
                onClick={() => MGEventGetDataSetForTasksKanbanView(2)}
              >
                Load Data
              </a>
            </div>
          </div>
        </div>
        <div className="msg--body hidden-flow">
          <div className="msg--body__lt">
            <div className="msg--body__lt__head flex-d flex-ac flex-jsb">
              <div className="flex-d flex-ac toggle-btns">
                <a
                  className={`flex-d flex-ac flex-jc ${
                    filterData.CurrentView == "ResourceView" ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    setFilterData((prevState: any) => ({
                      ...prevState,
                      CurrentView: "ResourceView",
                    }));
                  }}
                >
                  Resource View
                </a>
                <a
                  className={`flex-d flex-ac flex-jc ${
                    filterData.CurrentView == "StatusView" ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    setFilterData((prevState: any) => ({
                      ...prevState,
                      CurrentView: "StatusView",
                    }));
                  }}
                >
                  Status View
                </a>
                <a
                  className={`flex-d flex-ac flex-jc ${
                    filterData.CurrentView == "ProjectView" ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    setFilterData((prevState: any) => ({
                      ...prevState,
                      CurrentView: "ProjectView",
                    }));
                  }}
                >
                  Project View
                </a>
              </div>
            </div>
            <div className="msg--body__messages p-all10">
              {filterData.CurrentView == "ResourceView" &&
                TasksList.length > 0 && (
                  <ResourceView
                    ResourceList={ResourceList}
                    TasksList={TasksList}
                    handleChildClick={handleChildClick}
                  ></ResourceView>
                )}

              {filterData.CurrentView == "StatusView" &&
                TasksList.length > 0 && (
                  <StatusView
                    StatusList={StatusList}
                    TasksList={TasksList}
                    handleChildClick={handleChildClick}
                  ></StatusView>
                )}

              {filterData.CurrentView == "ProjectView" &&
                TasksList.length > 0 && (
                  <ProjectView
                    CurrentProjectsList={CurrentProjectsList}
                    TasksList={TasksList}
                    handleChildClick={handleChildClick}
                  ></ProjectView>
                )}
            </div>
            {CurrentlySelectedEvent != null &&
              CurrentlySelectedEvent.Name != "" && (
                <EventDetailsView
                  MGWorkspaceID={filterData.MGWorkspaceID}
                  EventDetails={CurrentlySelectedEvent}
                  handleChildClick={handleChildClick}
                ></EventDetailsView>
              )}
          </div>
        </div>
      </div>

      {showAddMGEventModal && (
        <div className="task-detail-fix custom--scroll ">
          <div className="flex-d flex-ac flex-jsb p-all10">
            <h3 className="event-title">Add Event</h3>
            <a
              className="close-help"
              onClick={() => setShowAddMGEventModal(false)}
            >
              <img
                src="/images/icon-cross.png"
                width="20px"
                height="20px"
                alt="cross"
              />
            </a>
          </div>
          <div className="grid-d p-lr10 font-14">
            <div className="modal--create__inputs mt--20">
              <span className="input--text">Assigned To:</span>
              <select
                key={"ResourceDDL"}
                className="input--type__text"
                value={MGEventData.SiteUserID}
                onChange={(e) => {
                  setMGEventData((prevState: any) => ({
                    ...prevState,
                    SiteUserID: e.target.value,
                  }));
                }}
              >
                <option value="0">Please Select</option>
                {ResourceList &&
                  ResourceList.map((item: any, index: any) => (
                    <React.Fragment key={"ResourceList" + index}>
                      <option
                        value={item.SiteUserID}
                        key={"resource" + item.SiteUserID}
                      >
                        {item.Name}
                      </option>
                    </React.Fragment>
                  ))}
              </select>
            </div>
            <div className="modal--create__inputs mt--20">
              <span className="input--text">Status:</span>
              <select
                key={"StatusDDL"}
                className="input--type__text"
                value={MGEventData.MGEventStatusID}
                onChange={(e) => {
                  setMGEventData((prevState: any) => ({
                    ...prevState,
                    MGEventStatusID: e.target.value,
                  }));
                }}
              >
                <option value="0">Please Select</option>
                {StatusList &&
                  StatusList.map((item: any, index: any) => (
                    <React.Fragment key={"StatusList" + index}>
                      <option
                        value={item.MGEventStatusID}
                        key={"Status" + item.MGEventStatusID}
                      >
                        {item.Name}
                      </option>
                    </React.Fragment>
                  ))}
              </select>
            </div>
            <div className="modal--create__inputs mt--20">
              <span className="input--text">Project:</span>
              <select
                key={"ProjectDDL"}
                className="input--type__text"
                value={MGEventData.MGProjectID}
                onChange={(e) => {
                  setMGEventData((prevState: any) => ({
                    ...prevState,
                    MGProjectID: e.target.value,
                  }));
                }}
              >
                <option value="0">Please Select</option>
                {ProjectsList &&
                  ProjectsList.map((item: any, index: any) => (
                    <React.Fragment key={"ProjectList" + index}>
                      <option
                        value={item.MGProjectID}
                        key={"Project" + item.MGProjectID}
                      >
                        {item.Name}
                      </option>
                    </React.Fragment>
                  ))}
              </select>
            </div>
            <div className="modal--create__inputs mt--20">
              <span className="input--text">Name:</span>
              <input
                className="input--type__text"
                value={MGEventData.Name}
                onChange={(e) =>
                  setMGEventData((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
                type="text"
              />
            </div>
            <div className="modal--create__inputs mt--20">
              <span className="input--text">Description:</span>
              <textarea
                className="input--type__text"
                value={MGEventData.Description}
                onChange={(e) =>
                  setMGEventData((prevState: any) => ({
                    ...prevState,
                    Description: e.target.value,
                  }))
                }
              />
            </div>
            <div className="modal--button mt--20 flex-gap10">
              <a onClick={() => MGEventSaveCustom()}>Save</a>
              <a onClick={() => setShowAddMGEventModal(false)}>Cancel</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TasksKanbanView;
