import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import moment from "moment";
import {
  GetSelectedIDsFromListWithComma,
  SortTheList,
} from "../../Shared/Utility";
type Props = {
  ResourceList: [];
  TasksList: [];
  handleChildClick: Function;
};
export default function ResourceView(props: Props) {
  const [SortedTasksList, SetSortedTasksList] = useState<any>([]);

  useEffect(() => {
    let SortedTasksList = SortTheList(props.TasksList, "ResourceOrder", "A");
    SetSortedTasksList(SortedTasksList);
  }, [props.TasksList]);

  return (
    <>
      <div className="grid-d w-100">
        <div className="flex-d custom--scroll flex-gap30">
          {props.ResourceList &&
            props.ResourceList.map((item: any, index: any) => (
              <div className="task-board" key={"resource" + index}>
                <React.Fragment>
                  <>
                    <span className="task-head">
                      {item.Name}
                      <a
                        onClick={() => {
                          props.handleChildClick(4, item);
                        }}
                        title="Add Event"
                      >
                        <img
                          src="/images/plus.png"
                          alt="Add Event"
                          width="18px"
                          height="18px"
                        />
                      </a>
                    </span>

                    <div
                      className="task-list custom--scroll grid-d
                    flex-gap15"
                    >
                      {SortedTasksList &&
                        SortedTasksList.map((taskItem: any, taskIndex: any) => (
                          <React.Fragment key={"tasklist" + taskIndex}>
                            {item.SiteUserID == taskItem.SiteUserID && (
                              <div
                                className="s-tasks"
                                onClick={(e) => {
                                  props.handleChildClick(1, taskItem);
                                }}
                              >
                                <span className="flex-d">{taskItem.Name}</span>
                                {/* <span className="flex-d">
                                  {taskItem.Description}
                                </span> */}
                                <span className="flex-d flex-jse asgn-task">
                                  {taskItem.AssignedTo}
                                </span>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </>
                </React.Fragment>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
