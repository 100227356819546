import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import TasksComm from "./TasksComm";

type Props = {
  MGWorkspaceID: any;
  EventDetails: any;
  handleChildClick: Function;
};
export default function EventDetailsView(props: Props) {
  const [expandView, setExpandView] = useState(false);

  const [filterData, setFilterData] = useState({
    MGMessageCategoryID: 8,
    MGMessageChannelID: 0,
    ShowInternalChat: true,
    ShowGeneralChat: true,
  });

  function CreateChannel() {
    let object: any = {};
    object.typeid = 1;
    object.mgworkspaceid = props.MGWorkspaceID;
    object.mgmessagechannelid = 0;
    object.mgmessagecategoryid = filterData.MGMessageCategoryID;
    object.permissiontype = 1;
    object.title = props.EventDetails.Name;
    object.description = props.EventDetails.Description;
    object.originalchannelid = 0;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGMessage/UpdateChannelInfo", object)
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MessageChannel =
            response.data.Data[response.data.Data.length - 1];
          let MGMessageChannelID = 0;
          if (l_MessageChannel.length > 0) {
            MGMessageChannelID = l_MessageChannel[0]["MGMessageChannelID"];
            MGEventUpdateCustom(3, MGMessageChannelID);
          }
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function MGEventUpdateCustom(type: number, value: number) {
    let object = {} as any;
    object.mgeventid = props.EventDetails.MGEventID;
    object.type = type;
    object.value = value;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGEvent/MGEventUpdateCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
          props.handleChildClick(3, {});
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {props.EventDetails.Name && props.EventDetails.Name != "" && (
        <div
          className={`task-detail-fix custom--scroll ${
            expandView ? "expand-view" : ""
          }`}
        >
          <div className="flex-d flex-ac flex-jsb p-all10">
            <a
              onClick={(e) => {
                setExpandView(!expandView);
              }}
            >
              {!expandView && (
                <>
                  <img
                    src="/images/rightg.png"
                    alt="img"
                    width="8px"
                    height="16px"
                    className="rotate-180d"
                  />
                </>
              )}
              {expandView && (
                <>
                  <img
                    src="/images/rightg.png"
                    alt="img"
                    width="8px"
                    height="16px"
                  />
                </>
              )}
            </a>

            <a
              onClick={(e) => {
                setExpandView(false);
                props.handleChildClick(2, {});
              }}
            >
              <img
                src="/images/icon-cross.png"
                width="20px"
                height="20px"
                alt="attachment"
              />
            </a>
          </div>

          <>
            <div className="grid-d p-lr10 font-14 temp-height">
              <span className="flex-d">
                {props.EventDetails.Name}
                {props.EventDetails.InternalChannelCode == "" && expandView && (
                  <span
                    onClick={() => {
                      CreateChannel();
                    }}
                  >
                    Start Internal Chat
                  </span>
                )}
              </span>
              <span className="flex-d">{props.EventDetails.Description}</span>
              <span className="flex-d">{props.EventDetails.AssignedTo}</span>

              {props.EventDetails.InternalChannelCode != "" && expandView && (
                <div>
                  <span
                    onClick={(e) => {
                      let ShowInternalChat = !filterData.ShowInternalChat;
                      setFilterData((prevState: any) => ({
                        ...prevState,
                        ShowInternalChat: ShowInternalChat,
                      }));
                    }}
                  >
                    Internal
                  </span>
                  {props.EventDetails.InternalChannelCode != "" &&
                    filterData.ShowInternalChat && (
                      <div className="task-chat-sm">
                        <TasksComm
                          workspaceid={props.MGWorkspaceID}
                          chennelid={props.EventDetails.InternalChannelCode}
                        />
                      </div>
                    )}
                </div>
              )}
            </div>

            <div>
              {expandView && (
                <span
                  onClick={(e) => {
                    let ShowGeneralChat = !filterData.ShowGeneralChat;
                    setFilterData((prevState: any) => ({
                      ...prevState,
                      ShowGeneralChat: ShowGeneralChat,
                    }));
                  }}
                >
                  General
                </span>
              )}
              {props.EventDetails.GeneralChannelCode != "" &&
                filterData.ShowGeneralChat && (
                  <div className="task-chat-sm">
                    <TasksComm
                      workspaceid={props.MGWorkspaceID}
                      chennelid={props.EventDetails.GeneralChannelCode}
                    />
                  </div>
                )}
            </div>
          </>
        </div>
      )}
    </>
  );
}
